
import BankAccounts from './components/bank-accounts/BankAccounts';
import SupportCentre from './components/support-centre/SupportCentre';
import FinancialHealth from './components/financial-health/FinancialHealth';
import Corona from './components/corona/corona';
import PremierProducts from './components/premier-products/PremierProducts';
import PremierSelect from './components/premier-select/PremierSelect';
import PremierSupport from './components/premier-support/PremierSupport';
import BusinessLoans from './components/business-loans/BusinessLoans';
import RunningBusiness from './components/running-business/RunningBusiness';
import BusinessSecurity from './components/business-security/BusinessSecurity';

import * as img from './images';

const personalRoute = '/h/personal'
const premierRoute = '/h/premier'
const businessRRoute = '/h/business'
const currentRoute = '/h/business'


export const options = [
  {
    content: [
      {
        id: 1,
        name: "PERSONAL",
        titleIcon: <i className="fa fa-paragraph"></i>,
        children: [
          {
            titleIcon: <i className="fa fa-opera"></i>,
            content: [
              {
                id: 2,
                name: "Bank Account",
                to: `/#${personalRoute}/bank-accounts`
              }
            ]
          },

          {
            titleIcon: <i className="fa fa-opera"></i>,
            content: [
              {
                id: 3,
                name: "Help and Support",
                to: `/#${personalRoute}/support`
              }
            ]
          },


          {
            titleIcon: <i className="fa fa-opera"></i>,
            content: [
              {
                id: 4,
                name: "Financial Health",
                to: `/#${personalRoute}/financial-health`
              }
            ]
          },


          {
            titleIcon: <i className="fa fa-opera"></i>,
            content: [
              {
                id: 5,
                name: "Corona Virus",
                to: `/#${personalRoute}/coronavirus`
              }
            ]
          },


        ]
      }
    ] 
  },

  {
    content: [
      {
        id: 6,
        name: "PREMIER",
        titleIcon: <i className="fa fa-paragraph"></i>,
        children: [
          {
            titleIcon: <i className="fa fa-opera"></i>,
            content: [
              {
                id: 7,
                name: "Premier Products",
                to: `${premierRoute}/premier-products`
              }
            ]
          },

          {
            titleIcon: <i className="fa fa-opera"></i>,
            content: [
              {
                id: 8,
                name: "Help and Support",
                to: `${personalRoute}/support`
              }
            ]
          },


          {
            titleIcon: <i className="fa fa-opera"></i>,
            content: [
              {
                id: 9,
                name: "Premier Select",
                to: `${premierRoute}/premier-select`
              }
            ]
          },


          {
            titleIcon: <i className="fa fa-opera"></i>,
            content: [
              {
                id: 10,
                name: "Premier Help and Support",
                to: `${premierRoute}/help-and-support`
              }
            ]
          },


        ]
      }
    ] 
  },

  {
    content: [
      {
        id: 11,
        name: "BUSINESS",
        titleIcon: <i className="fa fa-paragraph"></i>,
        children: [
          {
            titleIcon: <i className="fa fa-opera"></i>,
            content: [
              {
                id: 12,
                name: "Business loans and finance",
                to: `/#${businessRRoute}/business-loans`
              }
            ]
          },

          {
            titleIcon: <i className="fa fa-opera"></i>,
            content: [
              {
                id: 13,
                name: "Running a business",
                to: `/#${businessRRoute}/running-a-business`
              }
            ]
          },


          {
            titleIcon: <i className="fa fa-opera"></i>,
            content: [
              {
                id: 14,
                name: "Business Security",
                to: `/#${businessRRoute}/business-security`
              }
            ]
          },


          {
            titleIcon: <i className="fa fa-opera"></i>,
            content: [
              {
                id: 15,
                name: "Coronavirus Support",
                to: `/#${businessRRoute}/help-and-support`
              }
            ]
          },


        ]
      }
    ] 
  },


  {
    content: [
      {
        id: 16,
        name: "CONTACT",
        titleIcon: <i className="fa fa-paragraph"></i>,
        children: [
          {
            titleIcon: <i className="fa fa-opera"></i>,
            content: [
              {
                id: 17,
                name: "How to reach us",
                to: `../#/h/contact`
              }
            ]
          },
        ]
      }
    ] 
  },



];

export const routingData = [
  [
    {
      to: `${personalRoute}/bank-accounts`,
      linkText: 'Bank Account',
      component: BankAccounts
    },
    {
      to: `${personalRoute}/support`,
      linkText: 'Support Centre',
      component: SupportCentre
    },
    {
      to: `${personalRoute}/financial-health`,
      linkText: 'Financial Health',
      component: FinancialHealth
    },
    {
      to: `${personalRoute}/coronavirus`,
      linkText: 'Coronavirus Support',
      component: Corona
    }
  ],
  [
    {
      to: `${premierRoute}/premier-products`,
      linkText: 'What could Premier do for you ?',
      component: PremierProducts
    },
    {
      to: `${premierRoute}/premier-select`,
      linkText: 'Premier Select',
      component: PremierSelect
    },
    {
      to: `${premierRoute}/help-and-support`,
      linkText: 'Help and Support',
      component: PremierSupport
    }
  ],
  [
    {
      to: `${businessRRoute}/business-loans`,
      linkText: 'Business loans and finance',
      component: BusinessLoans
    },
    {
      to: `${businessRRoute}/running-a-business`,
      linkText: 'Running a business',
      component: RunningBusiness
    },

    {
      to: `${businessRRoute}/business-security`,
      linkText: 'Business Security',
      component: BusinessSecurity
    },
    {
      to: `${businessRRoute}/help-and-support`,
      linkText: 'Coronavirus Support',
      component: Corona
    }
  ],
]


export const switchData = [
  [
    {
      image: img.laughingMan,
      caption: 'Simplicty of switching your Current Account',
      switchText: `Want to keep your banking all in one place? It only takes 7 days
      and your Direct Debits will be moved over, and all the tricky admin bits will
      be covered.`,
      switchLink: `/application`,
      switchLinkText: 'Switch to us'
    },
    {
      image: img.helpingHands,
      caption: 'A helping hand for your banking needs',
      switchText: `Visit our Support Centre for the help and support you need,
      including our frequently asked questions.`,
      switchLink: `${personalRoute}/support`,
      switchLinkText: 'Support centre'
    },
    {
      image: img.climate,
      caption: 'Becoming climate positive by 2025',
      switchText: `To find out how we aim to become climate positive, and for
      useful tips on ways to reduce your carbon footprint, visit our sustainable
      banking hub.`,
      switchLink: `${personalRoute}/financial-health`,
      switchLinkText: 'Sustainable banking'
    }
  ],
  [
    {
      image: img.manWithPhoneLaughingImg,
      caption: 'Security',
      switchText: `Our Secure Banking Promise puts your safety and security
      at the top of our agenda.`,
      switchLink: '/personal',
      switchLinkText: 'Switch to us'
    },
    {
      image: img.girlPressingPhoneImg,
      caption: 'Versatility',
      switchText: `From your phone on the bus, to your tablet on the sofa –
      manage your digital finances on the go.`,
      switchLink: '/personal',
      switchLinkText: 'Support centre'
    },
    {
      image: img.deckOfFilesImg,
      caption: 'Simplicty',
      switchText: `Switching is simple. We’ll move over your Direct Debits and
      cover those tricky admin bits. `,
      switchLink: '/personal',
      switchLinkText: 'Sustainable banking'
    }
  ],
  [
    {
      caption: 'Apply for a Bounce Back Loan (BBL)',
      switchText: `You could be eligible for lending from $2k to $50k over 6
      years for businesses impacted by coronavirus. No interest payments for
      12 months.`,
      switchLink: `${businessRRoute}/business-loans`,
      switchLinkText: 'Bounce Back Loan'
    },
    {
      caption: 'Top up your existing Bounce Back Loan',
      switchText: `If you've borrowed less than 25% of the annual turnover on
      your BBL application, you could apply for a top-up subject to an overall
      cap of $50k. Eligibility criteria applies.`,
      switchLink: `${businessRRoute}/business-loans`,
      switchLinkText: 'Bounce Back Loan Top-Up Scheme'
    },
    {
      caption: 'Repayment holiday for existing loans',
      switchText: `Royal Bank customers may be able to apply for a repayment
      holiday on their existing business loans for up to 12 months.
      Eligibility criteria applies.`,
      switchLink: `${businessRRoute}/business-security`,
      switchLinkText: 'Repayment holiday in detail'
    }
  ],
  [
    {
      image: img.backingCakeImg,
      caption: 'Support for Women in business',
      switchText: `We’ve got over 500 Women in Business specialists across the
      UK. Chat to them to find out how their knowledge and skills could help you and your business to grow.`,
      switchLink: '/personal',
      switchLinkText: 'Get specialists support'
    },
    {
      image: img.fixingGlassImg,
      caption: 'Join a booming business community',
      switchText: `Meet other business owners and build networks across the UK.
      You’ll be welcomed to a real, growing community, where you can learn from
      our digital courses.`,
      switchLink: '/personal',
      switchLinkText: 'B part of a Business Builder'
    },
    {
      image: img.blueTopImg,
      caption: 'Tools and advice for your business',
      switchText: `You’ll find HR, health and safety and employment law guidance
      on our online platform for business owners. See what it can help you and
      your business with today.`,
      switchLink: '/personal',
      switchLinkText: 'MentorLive for business'
    }
  ],
  [
    {
      image: img.accessibleBankingImg,
      caption: 'Accessible Banking',
      switchText: `If you have an impairment, disability or health condition,
      here’s how we’re making banking easier for you.`,
      switchLink: `${premierRoute}/premier-products`,
      switchLinkText: 'Accessbility Services'
    },
    {
      image: img.fixingGlassImg,
      caption: 'Want to complain?',
      switchText: `If something hasn’t gone as you expected, we’re sorry. To put
      things right, we need to know what went wrong. Let us know all about it
      online, or via WebChat.`,
      switchLink: `${premierRoute}/premier-products`,
      switchLinkText: 'Make a complaint'
    },
    {
      image: img.blueTopImg,
      caption: 'Avoid getting scammed',
      switchText: `Learn about fraudsters’ latest tricks, and get advice on how to
      combat them. If you’re a victim of fraud, we’ve plenty of support for you
      too.`,
      switchLink: `${premierRoute}/premier-products`,
      switchLinkText: 'Explore Security Centre'
    }
  ],
  [
    {
      image: img.phoneInHandImg,
      caption: 'Keep track of your spending',
      switchText: `Track your spending and set monthly budgets for things like
      shopping and eating out. App criteria apply. Available to customers aged
      16+. Only available for Personal and Premier Current accounts.`,
      switchLink: `${businessRRoute}/running-a-business`,
      switchLinkText: 'Get started with spending'
    },
    {
      image: img.layOnRockImg,
      caption: 'Set a savings goal',
      switchText: `On average, customers save twice as much when they save towards
      a goal. Set your goal in the app today. Available to existing customers with
      an instant access savings account.`,
      switchLink: `${businessRRoute}/running-a-business`,
      switchLinkText: 'Set a savings goal'
    },
    {
      image: img.happyCoupleImg,
      caption: 'Check your credit score',
      switchText: `You can check your credit score for free in our mobile app.
      Check it as often as you like - it doesn't affect your credit score. App
      criteria apply. Credit score available once opted in through the app, to
      customers aged 18+, with a UK address and is provided by TransUnion.`,
      switchLink: `${businessRRoute}/running-a-business`,
      switchLinkText: 'Check my credit score'
    }
  ],
  [
    {
      image: img.euroImg,
      caption: '1. Your income',
      switchText: `Do you pay $100k sole or $120k joint income into your Royal Bank account?`,
    },
    {
      image: img.mortgageImg,
      caption: '2. Your mortgage borrowings',
      switchText: `Do you have a mortgage of at least $500k with us?`,
    },
    {
      image: img.investmentImg,
      caption: 'Your savings or investments',
      switchText: `Do you have $100k savings or investments held with us?`,
    }
  ],
  [
    {
      image: img.businessPeopleImg,
      caption: 'One-to-one support',
      switchText: `Whether you're reviewing your borrowing needs, planning
      that dream holiday or getting ready for retirement. Your Premier
      Banking Manager is equipped to help you achieve your financial goals.`,
    },
    {
      image: img.umbrellaImg,
      caption: 'Insurance cover',
      switchText: `One easy to manage portfolio. If you need help, you'll
      have specialists to help you get the cover you need and claims support,
      day or night. Cover underwritten by U K Insurance Limited.`,
    },
    {
      image: img.grandsonImg,
      caption: 'Protect your wealth',
      switchText: `Help ensure your money and assets are shared according
      to your wishes. Our Will-writing & Executor Service will help you to
      do so in the most efficient way, giving you peace of mind. A fee will
      apply. `,
    }
  ],
  [
    {
      image: img.cameraImg,
      caption: 'Have ID ready',
      switchText: `We'll need some details from you, such as your address,
      history, monthly income and ID`
    },
    {
      image: img.laptopImage,
      caption: 'Start your application',
      switchText: `You can apply online right now if you are ready, just use
      the section below to tell us about yourself.`
    },
    {
      image: img.clockImg,
      caption: 'How long will it take?',
      switchText: `You should be able to complete the online application
      within an average lunch break`
    }
  ],
  [
    {
      caption: 'Download our app',
      switchText: `Now you can have Premier banking at your fingertips, from
      checking balances to making transfers and payments.`
    },
    {
      caption: 'Digital banking',
      switchText: `You can manage and keep track of your finances 24/7. All
      you need is internet access.`
    },
    {
      caption: 'Expert help 24/7',
      switchText: `Contact us by webchat or phone, morning, noon and night.
      Our Premier 24 telephone banking team have the expert knowledge and
      resources to help you, whenever you need it.`
    }
  ],
  [
    {
      image: img.threeFamilyImg,
      caption: 'How to avoid scams',
      switchText: `Find out about fraudsters’ latest and most common tricks, get
      advice on how to combat them and some help if you’re a victim.`
    },
    {
      image: img.blackPhoneImg,
      caption: 'How to make a complaint',
      switchText: `We’re very sorry if something went wrong. If you feel you need
      to complain, the simplest way of doing so is online.`
    },
    {
      image: img.coupleStandingImg,
      caption: 'How we’re making banking more accessible',
      switchText: `Find out how our accessibility services help with everyday
      banking, including our accessible debit card developed with the RNIB.`
    }
  ],
]

export const accordionData = [
  {
    accordionHeading: 'How will a Financial Health Check benefit me?',
    accordionText: `Whether you're looking for peace of mind or to find out
    something specific, the financial health check will do the job. <br /><br />
    The free financial health check service is a review with one of our highly
    trained senior personal bankers, over the phone or face-to-face. <br /><br />
    We'll consider all areas of your finances to help you make the best decisions
    about what you feel is right for you. <br /><br />If it turns out you either
    need or want more specialist help, we can refer you to other specialist team
    members.`
  },
  {
    accordionHeading: 'Do I have to be a Royal Bank of America customer to get a Financial Health Check?',
    accordionText: `Not at all. If you would like to chat to one of our senior
    personal bankers, and find out how we could help you make the most of your money, please get in touch today. `
  },
  {
    accordionHeading: 'How long does the Health Check last?',
    accordionText: `Planning for tomorrow starts today. All it takes is a relaxed
    20 minutes chat with one of our highly qualified personal bankers.`
  },
  {
    accordionHeading: 'Do I need to bring anything with me to the Health Check?',
    accordionText: `To help us get the most from it, we would really appreciate if
    you could bring the following to your appointment:
      <ol>
        <li>
          Your smartphone and/or tablet devices. We will show you how to make the
          most of your banking with these, so make sure you know your log in details
          for your app store
        </li>
        <li>
          Details of any banking you have elsewhere such as current accounts and
          savings
        </li>
        <li>
          Your most recent mortgage statement or any loan or credit card statements
          you have
        </li>
        <li>
          Details of the protection you have in place for you, your family and your
          home
        </li>
      </ol>`
  },
  {
    accordionHeading: `I'm not going to make it, how do I cancel my appointment?`,
    accordionText: `Please note that we know it's easy to forget an appointment
    but time slots may become limited. <br /><br />Please reschedule as early as possible if
    you know you can't make it by calling us on the number below:<br/><br />Lines
    are open: <strong>(+44 (0) 23 8022 6443, +44 (0) 42 4841 2844)</strong> <br /><br /> 7 days a week 8am – 8pm (Automated service 24/7)`
  }
]


export const personalImageServices = [
  {
    to: `${businessRRoute}/business-security`,
    linkText: 'Select the right mortgage',
    image: img.directionIcon
  },
  {
    to: `${personalRoute}/support`,
    linkText: 'Visit Support Centre',
    image: img.supportIcon
  },
  {
    to: `/application`,
    linkText: 'Banking with Royal Bank',
    image: img.padlockIcon
  },
  {
    to: `/login`,
    linkText: 'Login to Digital Banking',
    image: img.laptopIcon
  }
]


export const premierImageServices = [
  {
    to: `${currentRoute}/business-loans`,
    linkText: 'Discover Premier Banking',
    image: img.giftIcon
  },
  {
    to: `${personalRoute}/bank-accounts`,
    linkText: 'Find a current account',
    image: img.bagIcon
  },
  {
    to: `/application`,
    linkText: 'Open a savings account',
    image: img.pigIcon
  },
  {
    to: `${personalRoute}/bank-accounts`,
    linkText: 'Find a mortgage',
    image: img.mortgageIcon
  }
]


export const listWithTopBorder = [
  [
    {
      heading: "Reward",
      subheader: "Earn monthly Rewards",
      offer: "For $2 a month you'll get:",
      listItems: [
        "£4 a month back in Rewards for 2 or more Direct Debits (at least $2 each)",
        "£1 a month back in Rewards with 1 mobile app log in",
        "1% back in Rewards when you spend at our partner retailers: Caffe Nero, Europcar and more"
      ],
      listInfo: "To apply, you need to be 18+ and a UK resident. To hold a Reward account, you'll need to pay $1,250 into your eligible account every month"
    },
    {
      heading: "Student",
      subheader: "Get the proper uni essentials",
      offer: "What you'll get:",
      listItems: [
        "Interest free overdraft up to $2000 (£500 in your first term) 18+, subject to approval.",
        "Choose from 3 great offers; a tastecard, Amazon offer or National Express Coachcard",
        "Bank anytime, anywhere with our easy to use mobile app",
        "£10 monthly fee for the International student account",
        "No fee for UK students"
      ],
      listInfo: "To apply, you need to be 18+ and a UK resident. To hold a Reward account, you'll need to pay $1,250 into your eligible account every month"
    },
    {
      heading: "Child & Teen bank account - Revolve",
      subheader: "Helping 11-18 year olds get a head start with money management",
      offer: "What you'll get:",
      listItems: [
        "Interest payments on the account balance",
        "Visa Debit Card for your own use ",
        "Easy to use mobile app (with payment restrictions)",
        "No monthly fee"
      ],
      listInfo: "To apply, you need to be 18+ and a UK resident. To hold a Reward account, you'll need to pay $1,250 into your eligible account every month"
    },
    {
      heading: "Foundation account",
      listInfo: "If you've encountered difficulties with money, you may not be eligible for a number of our bank accounts and might be offered the Foundation account instead. You can't apply directly for this account. We may offer it to you if we think it's more suitable during an application for one of our other accounts."
    }
  ],
  [
    {
      heading: "Bounce Back Loan Scheme",
      subheader: "For businesses affected by coronavirus",
      listItems: [
        "Borrow from $2,000 up to £50,000 (up to a maximum of 25% of your turnover)",
        "Fixed 6 year loan term, with no early repayment fees if you wish to repay the loan early",
        "12 month capital repayment holiday is automatically applied at the start of the loan (this may mean you pay more interest over the term).",
        "Interest for the first 12 months is paid by the government, and then by you for the remainder of the loan term",
        "If you have borrowed less than 25% of the annual turnover you stated on your original Bounce Back Loan application form, you are eligible to apply for a Top-Up. The combined value of your original loan and Top-up cannot exceed 25% of your originally stated turnover, and is subject to an overall cap of £50,000. You may only apply for a Top-Up once",
        "Specific eligibility criteria applies"
      ],
    },
    {
      heading: "Small Business Loan",
      subheader: "A fast, affordable way to borrow",
      listItems: [
        "Fixed rate of interest",
        "Could help you achieve your business goals",
        "Suitable for most small to medium businesses",
        "Up to 10 year terms may be available on request",
        "Borrow from £1,000 up to £50,000 (subject to credit approval)"
      ],
    },
    {
      heading: "Arranged Business Overdraft",
      subheader: "A flexible, short-term safety net",
      listItems: [
        "No set minimum to pay back each month",
        "Pay interest only on the amount you use",
        "unsecured (subject to credit approval). You can also apply for more, however this may result in security being required",
        "Ideal for unexpected bills or emergencies"
      ],
    },
    {
      heading: "Business cards",
      subheader: "An ideal way to pay for day-to-day expenses",
      listItems: [
        "Choice of cards to fit your businesses needs",
        "Can be used to pay for goods and services",
        "Quick and easy to apply for online",
        "Minimum credit limit $500, maximum credit limit subject to status",
        "Annual fee may apply"
      ],
    },
  ],
  [
    {
      heading: "Fixed Rate Loan",
      subheader: "Offers the certainty of fixed repayments",
      listItems: [
        "No product fees or early repayment charges",
        "Terms are 3, 5, 7, 10 or 15 years",
        "Borrow between $25,001 to $10m (subject to approval)",
        "Option to take repayment holidays, including during a Family First event (conditions apply and you must keep paying the interest)",
      ],
    },
    {
      heading: "Variable Rate Loan",
      subheader: "A loan that takes advantage of the Royal Bank base rate",
      listItems: [
        "No early repayment charges",
        "Borrow from $25,001 upwards (subject to approval)",
        "Terms are between 3 months to 25 years",
        "Various repayment options and repayment holiday available, including during a Family First event (conditions apply and you must keep paying the interest)",
        "Changes in the Royal Bank base rate will affect the amount you need to pay to fully repay your loan."
      ],
    },
    {
      heading: "Commercial Mortgage",
      subheader: "Buy commercial property with the flexibility of repayments",
      listItems: [
        "Borrow from $25,001 with no upper limit on a variable interest rate mortgage or up to $10m on a fixed interest rate, subject to approval",
        "Fixed and variable interest rates available",
        "Mortgage terms available up to 25 years",
        "No early redemption charges"
      ],
    },
    {
      heading: "Real Estate Finance",
      subheader: "project that is built to last",
      listItems: [
        "Borrow from £50,000 upwards, subject to approval.",
        "Building and developing residential property to sell",
        "Investment in commercial premises to be leased out for business purposes",
        "Investment in commercial premises to be leased out for business purposes",
      ],
    },
  ],
  [
    {
      heading: "Access funds to improve your company’s working capital",
      subheader: "Access funds to improve your company’s working capital",
      offer: `Invoice finance is a way to borrow against the value of an unpaid
      invoice. You could get up to 90% of the value of an invoice. This is ideal
      if you get a sudden large contract through or have a just-in-time inventory
      strategy. We offer different types of Invoice Finance`,
      listItems: [
        "Release cash quickly and confidentially with Invoice Discounting",
        "We’ll chase unpaid invoices for you with Invoice Factoring",
        "Release cash from your existing assets with Asset Based Lending (minimum eligibility criteria apply)",
      ],
    },
    {
      heading: "Asset finance",
      subheader: "A more flexible way to purchase vehicles or equipment",
      offer: `Asset finance allows you to fund large purchases for your business.
      It can be used to purchase anything from machinery and vehicles to tech and
      agricultural equipment. We offer a range of options finance though our
      partner, Lombard.`,
      listItems: [
        "You take ownership of the asset with a Hire purchase",
        "You rent an asset rather than buying it outright with a Finance lease",
        "Finance cars and vans for your business with Contract hire",
        "Purchase high-value specialist equipment with an Operating lease",
      ],
    },
    {
      heading: "Social & Community Capital",
      subheader: "Help for social enterprises and community businesses",
      offer: "Community business loans for charities and social enterprises that don't qualify for mainstream loans.",
      listItems: [
        "Apply for loan funding from $30,000 to $750,000",
        "Loans are tailored to your business’ requirements by sector experts who will structure the loan to suit your operation",
        "You could get flexible terms - including capital repayment holidays if you need them (conditions apply and you must keep paying the interest)"
      ],
    },
  ],
  [
    {
      heading: "Invoice redirection",
      offer: `Have you received an unexpected request to change bank details from a supplier?`,
    },
    {
      heading: "Bogus Boss",
      offer: `Have you received an urgent request to transfer funds from a colleague that doesn't seem right?`,
    },
    {
      heading: "Telephone fraud",
      offer: `Fraudsters are known to impersonate the bank and other trusted organisations to trick you.`,
    },
    {
      heading: "Phishing emails",
      offer: `Have you received an unexpected email asking you to take action? `,
    },
    {
      heading: "Cyber threats",
      offer: `Fraudsters are known to use malicious software to compromise your devices.`,
    },
    {
      heading: "Insider fraud",
      offer: `Are you being defrauded from within your organisation?`,
    },
    {
      heading: "Text message fraud",
      offer: `SMS can be used to trick you into divulging personal or sensitive information.`,
    },
    {
      heading: "Purchase scams",
      offer: `Because they rely on the anonymity of the internet, it can be hard to spot a purchase scam.`,
    },
    {
      heading: "Investment fraud",
      offer: `Fake investment websites can be hard to tell apart from the real thing.`,
    }
  ],
]


export const listWithCheckData = [
  {
    header: 'Everyday bank account - Select',
    text: 'A bank account you can rely on for your day-to-day banking',
    listItems: ["Mobile banking app that's simple to use. Criteria applies.",
      "Pay your way; Apple Pay, Google Pay and Contactless Visa Debit Card. Limits apply.",
      "No monthly fee."
    ],
    info: "To apply, you need to be 18+ and a UK resident.",
    btnText: "View Accout",
    btnLink: "/hello"
  },
  {
    header: 'Premier Banking at a glance',
    text: "Premier Banking is a free service. You'll enjoy a whole host of benefits:",
    listItems: ["Premier bank accounts - fee-free or get extra benefits with one of our Reward accounts for a monthly fee.",
      "A Premier Banking Manager to help with your financial plans or guide you through more complex financial decisions.",
      "Access to products and specialist services designed to help meet your needs.",
      "Round the clock help, online or over the phone."
    ]
  },
  {
    header: "What you'll get with a Premier Select account",
    text: 'Premier Select:',
    listItems: ["No monthly fee",
      "Expert help online or on phone, one-to-one support when you need it",
      "Everyday banking tasks made easier with our mobile banking app. Criteria applies.",
      "Contactless Visa Debit Card"
    ],
    info: "To apply, you need to be 18 and above.",
    btnText: "Start my aplication",
    btnLink: "/application"
  },
  {
    listItems: [
      "You're aged 11 or over",
      "You have your account number and sort code handy",
      "for credit card only customers, you'll need your card number and expiry date"
    ]
  },
]


export const countryList = [ 
  {"name": "Select your Country"}, 
  {"name": "Afghanistan", "code": "AF"}, 
  {"name": "land Islands", "code": "AX"}, 
  {"name": "Albania", "code": "AL"}, 
  {"name": "Algeria", "code": "DZ"}, 
  {"name": "American Samoa", "code": "AS"}, 
  {"name": "AndorrA", "code": "AD"}, 
  {"name": "Angola", "code": "AO"}, 
  {"name": "Anguilla", "code": "AI"}, 
  {"name": "Antarctica", "code": "AQ"}, 
  {"name": "Antigua and Barbuda", "code": "AG"}, 
  {"name": "Argentina", "code": "AR"}, 
  {"name": "Armenia", "code": "AM"}, 
  {"name": "Aruba", "code": "AW"}, 
  {"name": "Australia", "code": "AU"}, 
  {"name": "Austria", "code": "AT"}, 
  {"name": "Azerbaijan", "code": "AZ"}, 
  {"name": "Bahamas", "code": "BS"}, 
  {"name": "Bahrain", "code": "BH"}, 
  {"name": "Bangladesh", "code": "BD"}, 
  {"name": "Barbados", "code": "BB"}, 
  {"name": "Belarus", "code": "BY"}, 
  {"name": "Belgium", "code": "BE"}, 
  {"name": "Belize", "code": "BZ"}, 
  {"name": "Benin", "code": "BJ"}, 
  {"name": "Bermuda", "code": "BM"}, 
  {"name": "Bhutan", "code": "BT"}, 
  {"name": "Bolivia", "code": "BO"}, 
  {"name": "Bosnia and Herzegovina", "code": "BA"}, 
  {"name": "Botswana", "code": "BW"}, 
  {"name": "Bouvet Island", "code": "BV"}, 
  {"name": "Brazil", "code": "BR"}, 
  {"name": "British Indian Ocean Territory", "code": "IO"}, 
  {"name": "Brunei Darussalam", "code": "BN"}, 
  {"name": "Bulgaria", "code": "BG"}, 
  {"name": "Burkina Faso", "code": "BF"}, 
  {"name": "Burundi", "code": "BI"}, 
  {"name": "Cambodia", "code": "KH"}, 
  {"name": "Cameroon", "code": "CM"}, 
  {"name": "Canada", "code": "CA"}, 
  {"name": "Cape Verde", "code": "CV"}, 
  {"name": "Cayman Islands", "code": "KY"}, 
  {"name": "Central African Republic", "code": "CF"}, 
  {"name": "Chad", "code": "TD"}, 
  {"name": "Chile", "code": "CL"}, 
  {"name": "China", "code": "CN"}, 
  {"name": "Christmas Island", "code": "CX"}, 
  {"name": "Cocos (Keeling) Islands", "code": "CC"}, 
  {"name": "Colombia", "code": "CO"}, 
  {"name": "Comoros", "code": "KM"}, 
  {"name": "Congo", "code": "CG"}, 
  {"name": "Congo, The Democratic Republic of the", "code": "CD"}, 
  {"name": "Cook Islands", "code": "CK"}, 
  {"name": "Costa Rica", "code": "CR"}, 
  {"name": "Cote D'Ivoire", "code": "CI"}, 
  {"name": "Croatia", "code": "HR"}, 
  {"name": "Cuba", "code": "CU"}, 
  {"name": "Cyprus", "code": "CY"}, 
  {"name": "Czech Republic", "code": "CZ"}, 
  {"name": "Denmark", "code": "DK"}, 
  {"name": "Djibouti", "code": "DJ"}, 
  {"name": "Dominica", "code": "DM"}, 
  {"name": "Dominican Republic", "code": "DO"}, 
  {"name": "Ecuador", "code": "EC"}, 
  {"name": "Egypt", "code": "EG"}, 
  {"name": "El Salvador", "code": "SV"}, 
  {"name": "Equatorial Guinea", "code": "GQ"}, 
  {"name": "Eritrea", "code": "ER"}, 
  {"name": "Estonia", "code": "EE"}, 
  {"name": "Ethiopia", "code": "ET"}, 
  {"name": "Falkland Islands (Malvinas)", "code": "FK"}, 
  {"name": "Faroe Islands", "code": "FO"}, 
  {"name": "Fiji", "code": "FJ"}, 
  {"name": "Finland", "code": "FI"}, 
  {"name": "France", "code": "FR"}, 
  {"name": "French Guiana", "code": "GF"}, 
  {"name": "French Polynesia", "code": "PF"}, 
  {"name": "French Southern Territories", "code": "TF"}, 
  {"name": "Gabon", "code": "GA"}, 
  {"name": "Gambia", "code": "GM"}, 
  {"name": "Georgia", "code": "GE"}, 
  {"name": "Germany", "code": "DE"}, 
  {"name": "Ghana", "code": "GH"}, 
  {"name": "Gibraltar", "code": "GI"}, 
  {"name": "Greece", "code": "GR"}, 
  {"name": "Greenland", "code": "GL"}, 
  {"name": "Grenada", "code": "GD"}, 
  {"name": "Guadeloupe", "code": "GP"}, 
  {"name": "Guam", "code": "GU"}, 
  {"name": "Guatemala", "code": "GT"}, 
  {"name": "Guernsey", "code": "GG"}, 
  {"name": "Guinea", "code": "GN"}, 
  {"name": "Guinea-Bissau", "code": "GW"}, 
  {"name": "Guyana", "code": "GY"}, 
  {"name": "Haiti", "code": "HT"}, 
  {"name": "Heard Island and Mcdonald Islands", "code": "HM"}, 
  {"name": "Holy See (Vatican City State)", "code": "VA"}, 
  {"name": "Honduras", "code": "HN"}, 
  {"name": "Hong Kong", "code": "HK"}, 
  {"name": "Hungary", "code": "HU"}, 
  {"name": "Iceland", "code": "IS"}, 
  {"name": "India", "code": "IN"}, 
  {"name": "Indonesia", "code": "ID"}, 
  {"name": "Iran, Islamic Republic Of", "code": "IR"}, 
  {"name": "Iraq", "code": "IQ"}, 
  {"name": "Ireland", "code": "IE"}, 
  {"name": "Isle of Man", "code": "IM"}, 
  {"name": "Israel", "code": "IL"}, 
  {"name": "Italy", "code": "IT"}, 
  {"name": "Jamaica", "code": "JM"}, 
  {"name": "Japan", "code": "JP"}, 
  {"name": "Jersey", "code": "JE"}, 
  {"name": "Jordan", "code": "JO"}, 
  {"name": "Kazakhstan", "code": "KZ"}, 
  {"name": "Kenya", "code": "KE"}, 
  {"name": "Kiribati", "code": "KI"}, 
  {"name": "Korea, Democratic People'S Republic", "code": "KP"}, 
  {"name": "Korea, Republic of", "code": "KR"}, 
  {"name": "Kuwait", "code": "KW"}, 
  {"name": "Kyrgyzstan", "code": "KG"}, 
  {"name": "Lao People'S Democratic Republic", "code": "LA"}, 
  {"name": "Latvia", "code": "LV"}, 
  {"name": "Lebanon", "code": "LB"}, 
  {"name": "Lesotho", "code": "LS"}, 
  {"name": "Liberia", "code": "LR"}, 
  {"name": "Libyan Arab Jamahiriya", "code": "LY"}, 
  {"name": "Liechtenstein", "code": "LI"}, 
  {"name": "Lithuania", "code": "LT"}, 
  {"name": "Luxembourg", "code": "LU"}, 
  {"name": "Macao", "code": "MO"}, 
  {"name": "Macedonia, The Former Yugoslav Republic of", "code": "MK"}, 
  {"name": "Madagascar", "code": "MG"}, 
  {"name": "Malawi", "code": "MW"}, 
  {"name": "Malaysia", "code": "MY"}, 
  {"name": "Maldives", "code": "MV"}, 
  {"name": "Mali", "code": "ML"}, 
  {"name": "Malta", "code": "MT"}, 
  {"name": "Marshall Islands", "code": "MH"}, 
  {"name": "Martinique", "code": "MQ"}, 
  {"name": "Mauritania", "code": "MR"}, 
  {"name": "Mauritius", "code": "MU"}, 
  {"name": "Mayotte", "code": "YT"}, 
  {"name": "Mexico", "code": "MX"}, 
  {"name": "Micronesia, Federated States of", "code": "FM"}, 
  {"name": "Moldova, Republic of", "code": "MD"}, 
  {"name": "Monaco", "code": "MC"}, 
  {"name": "Mongolia", "code": "MN"}, 
  {"name": "Montenegro", "code": "ME"},
  {"name": "Montserrat", "code": "MS"},
  {"name": "Morocco", "code": "MA"}, 
  {"name": "Mozambique", "code": "MZ"}, 
  {"name": "Myanmar", "code": "MM"}, 
  {"name": "Namibia", "code": "NA"}, 
  {"name": "Nauru", "code": "NR"}, 
  {"name": "Nepal", "code": "NP"}, 
  {"name": "Netherlands", "code": "NL"}, 
  {"name": "Netherlands Antilles", "code": "AN"}, 
  {"name": "New Caledonia", "code": "NC"}, 
  {"name": "New Zealand", "code": "NZ"}, 
  {"name": "Nicaragua", "code": "NI"}, 
  {"name": "Niger", "code": "NE"}, 
  {"name": "Nigeria", "code": "NG"}, 
  {"name": "Niue", "code": "NU"}, 
  {"name": "Norfolk Island", "code": "NF"}, 
  {"name": "Northern Mariana Islands", "code": "MP"}, 
  {"name": "Norway", "code": "NO"}, 
  {"name": "Oman", "code": "OM"}, 
  {"name": "Pakistan", "code": "PK"}, 
  {"name": "Palau", "code": "PW"}, 
  {"name": "Palestinian Territory, Occupied", "code": "PS"}, 
  {"name": "Panama", "code": "PA"}, 
  {"name": "Papua New Guinea", "code": "PG"}, 
  {"name": "Paraguay", "code": "PY"}, 
  {"name": "Peru", "code": "PE"}, 
  {"name": "Philippines", "code": "PH"}, 
  {"name": "Pitcairn", "code": "PN"}, 
  {"name": "Poland", "code": "PL"}, 
  {"name": "Portugal", "code": "PT"}, 
  {"name": "Puerto Rico", "code": "PR"}, 
  {"name": "Qatar", "code": "QA"}, 
  {"name": "Reunion", "code": "RE"}, 
  {"name": "Romania", "code": "RO"}, 
  {"name": "Russian Federation", "code": "RU"}, 
  {"name": "RWANDA", "code": "RW"}, 
  {"name": "Saint Helena", "code": "SH"}, 
  {"name": "Saint Kitts and Nevis", "code": "KN"}, 
  {"name": "Saint Lucia", "code": "LC"}, 
  {"name": "Saint Pierre and Miquelon", "code": "PM"}, 
  {"name": "Saint Vincent and the Grenadines", "code": "VC"}, 
  {"name": "Samoa", "code": "WS"}, 
  {"name": "San Marino", "code": "SM"}, 
  {"name": "Sao Tome and Principe", "code": "ST"}, 
  {"name": "Saudi Arabia", "code": "SA"}, 
  {"name": "Senegal", "code": "SN"}, 
  {"name": "Serbia", "code": "RS"}, 
  {"name": "Seychelles", "code": "SC"}, 
  {"name": "Sierra Leone", "code": "SL"}, 
  {"name": "Singapore", "code": "SG"}, 
  {"name": "Slovakia", "code": "SK"}, 
  {"name": "Slovenia", "code": "SI"}, 
  {"name": "Solomon Islands", "code": "SB"}, 
  {"name": "Somalia", "code": "SO"}, 
  {"name": "South Africa", "code": "ZA"}, 
  {"name": "South Georgia and the South Sandwich Islands", "code": "GS"}, 
  {"name": "Spain", "code": "ES"}, 
  {"name": "Sri Lanka", "code": "LK"}, 
  {"name": "Sudan", "code": "SD"}, 
  {"name": "Suriname", "code": "SR"}, 
  {"name": "Svalbard and Jan Mayen", "code": "SJ"}, 
  {"name": "Swaziland", "code": "SZ"}, 
  {"name": "Sweden", "code": "SE"}, 
  {"name": "Switzerland", "code": "CH"}, 
  {"name": "Syrian Arab Republic", "code": "SY"}, 
  {"name": "Taiwan, Province of China", "code": "TW"}, 
  {"name": "Tajikistan", "code": "TJ"}, 
  {"name": "Tanzania, United Republic of", "code": "TZ"}, 
  {"name": "Thailand", "code": "TH"}, 
  {"name": "Timor-Leste", "code": "TL"}, 
  {"name": "Togo", "code": "TG"}, 
  {"name": "Tokelau", "code": "TK"}, 
  {"name": "Tonga", "code": "TO"}, 
  {"name": "Trinidad and Tobago", "code": "TT"}, 
  {"name": "Tunisia", "code": "TN"}, 
  {"name": "Turkey", "code": "TR"}, 
  {"name": "Turkmenistan", "code": "TM"}, 
  {"name": "Turks and Caicos Islands", "code": "TC"}, 
  {"name": "Tuvalu", "code": "TV"}, 
  {"name": "Uganda", "code": "UG"}, 
  {"name": "Ukraine", "code": "UA"}, 
  {"name": "United Arab Emirates", "code": "AE"}, 
  {"name": "United Kingdom", "code": "GB"}, 
  {"name": "United States", "code": "US"}, 
  {"name": "United States Minor Outlying Islands", "code": "UM"}, 
  {"name": "Uruguay", "code": "UY"}, 
  {"name": "Uzbekistan", "code": "UZ"}, 
  {"name": "Vanuatu", "code": "VU"}, 
  {"name": "Venezuela", "code": "VE"}, 
  {"name": "Viet Nam", "code": "VN"}, 
  {"name": "Virgin Islands, British", "code": "VG"}, 
  {"name": "Virgin Islands, U.S.", "code": "VI"}, 
  {"name": "Wallis and Futuna", "code": "WF"}, 
  {"name": "Western Sahara", "code": "EH"}, 
  {"name": "Yemen", "code": "YE"}, 
  {"name": "Zambia", "code": "ZM"}, 
  {"name": "Zimbabwe", "code": "ZW"} 
  ]